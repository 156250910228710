import { useState, ReactNode } from 'react';
import { createRoot } from 'react-dom/client';

import { P } from '~/ui/components/text';

import { ConfirmAsyncOptions } from './shared';
import { DIALOG_ANIMATION_DISAPPEAR_MS } from '../Dialog/DialogContent';

import { ConfirmationDialog } from './index';

export function confirmAsync(
  message: ReactNode,
  { title, cancelButtonLabel, confirmButtonLabel, destructive }: ConfirmAsyncOptions = {}
) {
  return new Promise<boolean>(function (resolve) {
    const container = document.createElement('div');
    document.body.appendChild(container);

    const root = createRoot(container);

    function unmount() {
      document.body.removeChild(container);
      root.unmount();
    }

    root.render(
      <ConfirmAsyncDialogWrapper
        message={message}
        title={title}
        cancelButtonLabel={cancelButtonLabel}
        confirmButtonLabel={confirmButtonLabel}
        destructive={destructive}
        resolve={resolve}
        unmount={unmount}
      />
    );
  });
}

function ConfirmAsyncDialogWrapper({
  title,
  cancelButtonLabel,
  confirmButtonLabel,
  destructive,
  message,
  resolve,
  unmount,
}: ConfirmAsyncOptions & {
  message: ReactNode;
  resolve: (value: boolean | PromiseLike<boolean>) => void;
  unmount: () => void;
}) {
  const [isModalVisible, setIsModalVisible] = useState(true);

  return (
    <ConfirmationDialog
      isOpen={isModalVisible}
      title={title}
      cancelButtonLabel={cancelButtonLabel}
      confirmButtonLabel={confirmButtonLabel}
      destructive={destructive}
      onCancel={() => {
        setIsModalVisible(false);
        setTimeout(unmount, DIALOG_ANIMATION_DISAPPEAR_MS);
        resolve(false);
      }}
      onConfirm={() => {
        setIsModalVisible(false);
        setTimeout(unmount, DIALOG_ANIMATION_DISAPPEAR_MS);
        resolve(true);
      }}>
      {typeof message === 'string' ? <P>{message}</P> : message}
    </ConfirmationDialog>
  );
}
