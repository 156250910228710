import { ChangelogIcon } from '@expo/styleguide-icons/custom/ChangelogIcon';
import { PencilLineIcon } from '@expo/styleguide-icons/outline/PencilLineIcon';

import { getAssetUrl } from '~/common/utilities';
import { highlightedPosts } from '~/scenes/ChangelogScene/changelog-posts';
import blogPostsFile from '~/scenes/Index/blog-posts.json';

import { ContentTimelineContentContainer } from './ContentTimeline/ContentTimelineContentContainer';
import { ContentTimelineItem } from './ContentTimeline/ContentTimelineItem';
import { ContentTimelineTitle } from './ContentTimeline/ContentTimelineTitle';
import { ContentTimelineViewAllButton } from './ContentTimeline/ContentTimelineViewAllButton';

export function ContentSpotlight() {
  return (
    <div className="mb-4 grid grid-cols-2 gap-4 max-lg-gutters:grid-cols-1 max-lg-gutters:gap-8">
      <div>
        <ContentTimelineTitle title="Changelog" icon={<ChangelogIcon />} />
        <ContentTimelineContentContainer>
          {highlightedPosts.slice(0, 3).map((highlightedPost) => (
            <ContentTimelineItem
              key={highlightedPost.meta.title}
              title={highlightedPost.meta.title}
              date={highlightedPost.meta.date}
              href={`/changelog${highlightedPost.meta.slug}`}
              src={highlightedPost.meta.image}
            />
          ))}
        </ContentTimelineContentContainer>
        <ContentTimelineViewAllButton title="View Changelog" href="/changelog" />
      </div>
      <div>
        <ContentTimelineTitle title="Blog" icon={<PencilLineIcon />} />
        <ContentTimelineContentContainer>
          {blogPostsFile.blogPosts.map((blogPost) => (
            <ContentTimelineItem
              key={blogPost.id}
              src={blogPost.coverImage ?? getAssetUrl('/static/images/guides/guides-sdk.png')}
              title={blogPost.title}
              date={blogPost.pubDate}
              href={blogPost.link}
            />
          ))}
        </ContentTimelineContentContainer>
        <ContentTimelineViewAllButton title="Read Blog" href="/blog" />
      </div>
    </div>
  );
}
